import { Node } from 'tiptap'
import { wrappingInputRule, setBlockType, toggleWrap } from 'tiptap-commands'

export default class Alignement extends Node {

    // choose a unique name
    get name() {
        return 'alignment'
    }

    get defaultOptions() {
        return {
            aligns: ['left', 'center', 'right', 'justify'],
        }
    }

    // the prosemirror schema object
    // take a look at https://prosemirror.net/docs/guide/#schema for a detailed explanation
    get schema() {
        return {
            content: 'block+',
            group: 'block',
            defining: true,
            draggable: false,
            attrs: {
                align: {
                    default: 'left'
                },
            },
   
            parseDOM: this.options.aligns
                .map(a => ({
                    tag: 'div[style]',
                    getAttrs : dom => {
                         let align = dom.getAttribute("style").replace('text-align: ', '')
                         return this.options.aligns.indexOf(align) > -1 ? {align} :false
                    }
                })),
       
            toDOM: (node) => ['div', { style: 'text-align: ' + node.attrs.align }, 0],
        }
    }

    commands({ type, schema }) {
        return (attrs) => toggleWrap(type, attrs)
    }

    inputRules({ type }) {
        return [
            wrappingInputRule(/^\s*>\s$/, type),
        ]
    }

}