<template>
    <div>
        <v-hover v-slot="{ hover }">
            <div
                    class="cell rounded-lg overflow-hidden transition-cubic"
                    :class="full_screen ? 'fullscreen':''"
                    style="position: relative"
                    :style="[focus ? {border: '1px solid var(--v-primary-base) !important', boxShadow: '0 0 0 1px var(--v-primary-base)' } : hover ? { border: '1px solid var(--v-inputHover-base) !important' } : { border: '1px solid var(--v-input-base) !important' }, full_screen ? {height: ($vuetify.breakpoint.height * 0.9) + 'px', width: ($vuetify.breakpoint.width * 0.9) + 'px'} : {}]"
            >
                <editor-menu-bar v-if="multiligne" :editor="editor" v-slot="{ commands, isActive }" class="cell2">
                    <v-row class="mx-0" align="center">

                        <Button :show="!source_code" :click="(e) => commands.bold(e)" :toolTip="$t('Editor.bold')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="isActive.bold() ? 'secondary': 'cell2'" xSmall tile flat iconVal="mdi-format-bold"/>

                        <Button :show="!source_code" :click="(e) => commands.italic(e)" :toolTip="$t('Editor.italic')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="isActive.italic() ? 'secondary': 'cell2'" xSmall tile flat iconVal="mdi-format-italic"/>

                        <Button :show="!source_code" :click="(e) => commands.strike(e)" :toolTip="$t('Editor.strike')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="isActive.strike() ? 'secondary': 'cell2'" xSmall tile flat iconVal="mdi-format-strikethrough"/>

                        <Button :show="!source_code" :click="(e) => commands.underline(e)" :toolTip="$t('Editor.under')"  styles="max-width: 30px; min-width: 30px" classes="px-0" :color="isActive.underline() ? 'secondary': 'cell2'" xSmall tile flat iconVal="mdi-format-underline"/>

                        <Button :show="!source_code" :click="(e) => commands.code(e)" :toolTip="$t('Editor.code')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="isActive.code() ? 'secondary': 'cell2'" xSmall tile flat iconVal="mdi-code-tags"/>

                        <v-menu offset-y rounded="lg">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        v-show="!source_code"
                                        v-on="on"
                                        small
                                        tile
                                        depressed
                                        color="transparent"
                                        style="max-width: 30px; min-width: 30px"
                                >
                                    <v-icon size="20">mdi-format-size</v-icon>
                                </v-btn>
                            </template>
                            <v-list class="py-0" dense>
                                <v-list-item @click="commands.heading({ level: 1 })"
                                             :class="{ 'secondary': isActive.heading({ level: 1 }) }">
                                    <div class="paragraph" :class="isActive.heading({ level: 1 }) ? 'white--text':''">{{ $t('Editor.h1') }}</div>
                                </v-list-item>
                                <v-list-item @click="commands.heading({ level: 2 })"
                                             :class="{ 'secondary': isActive.heading({ level: 2 }) }">
                                    <div class="paragraph" :class="isActive.heading({ level: 2 }) ? 'white--text':''">{{ $t('Editor.h2') }}</div>
                                </v-list-item>
                                <v-list-item @click="commands.heading({ level: 3 })"
                                             :class="{ 'secondary': isActive.heading({ level: 3 }) }">
                                    <div class="paragraph" :class="isActive.heading({ level: 3 }) ? 'white--text':''">{{ $t('Editor.h3') }}</div>
                                </v-list-item>
                                <v-list-item @click="commands.paragraph" :class="{ 'secondary': isActive.paragraph() }">
                                    <div class="paragraph" :class="isActive.paragraph() ? 'white--text':''">{{ $t('Editor.p') }}</div>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-menu offset-y rounded="lg">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        v-show="!source_code"
                                        v-on="on"
                                        small
                                        tile
                                        depressed
                                        color="transparent"
                                        style="max-width: 30px; min-width: 30px"
                                >
                                    <v-icon size="20">mdi-table-large</v-icon>
                                </v-btn>
                            </template>
                            <v-list class="py-0" dense>
                                <v-list-item @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })" :class="{ 'secondary': isActive.table() }" >
                                    <v-icon>mdi-table-large-plus</v-icon>
                                </v-list-item>
                                <v-list-item @click="commands.deleteTable()">
                                    <v-icon>mdi-table-large-remove</v-icon>
                                </v-list-item>
                                <v-list-item @click="commands.addColumnBefore()">
                                    <v-icon>mdi-table-column-plus-before</v-icon>
                                </v-list-item>
                                <v-list-item @click="commands.addColumnAfter()">
                                    <v-icon>mdi-table-column-plus-after</v-icon>
                                </v-list-item>
                                <v-list-item @click="commands.deleteColumn()">
                                    <v-icon>mdi-table-column-remove</v-icon>
                                </v-list-item>
                                <v-list-item @click="commands.addRowBefore()">
                                    <v-icon>mdi-table-row-plus-before</v-icon>
                                </v-list-item>
                                <v-list-item @click="commands.addRowAfter()">
                                    <v-icon>mdi-table-row-plus-after</v-icon>
                                </v-list-item>
                                 <v-list-item @click="commands.deleteRow()">
                                    <v-icon>mdi-table-row-remove</v-icon>
                                </v-list-item>
                                 <v-list-item @click="commands.toggleCellMerge()">
                                    <v-icon>mdi-table-merge-cells</v-icon>
                                </v-list-item>
                            </v-list>


                        </v-menu>

                        <v-divider vertical />

                        <Button :show="!source_code" :click="() => toggleAlignment(commands, 'left', isActive)" :toolTip="$t('Editor.left')" styles="max-width: 30px; min-width: 30px" :color="isActive.alignment({align: 'left'}) ? 'secondary': 'cell2'" classes="px-0" xSmall tile flat iconVal="mdi-format-align-left"/>

                        <Button :show="!source_code" :click="(e) => toggleAlignment(commands, 'center', isActive)" :toolTip="$t('Editor.center')" styles="max-width: 30px; min-width: 30px" :color="isActive.alignment({align: 'center'}) ? 'secondary': 'cell2'" classes="px-0" xSmall tile flat iconVal="mdi-format-align-center"/>

                        <Button :show="!source_code" :click="(e) => toggleAlignment(commands, 'right', isActive)" :toolTip="$t('Editor.right')" styles="max-width: 30px; min-width: 30px" :color="isActive.alignment({align: 'right'}) ? 'secondary': 'cell2'" classes="px-0" xSmall tile flat iconVal="mdi-format-align-right"/>

                        <Button :show="!source_code" :click="(e) => toggleAlignment(commands, 'justify', isActive)" :toolTip="$t('Editor.justify')" styles="max-width: 30px; min-width: 30px" :color="isActive.alignment({align: 'justify'}) ? 'secondary': 'cell2'" classes="px-0" xSmall tile flat iconVal="mdi-format-align-justify"/>

                        <v-divider vertical />

                        <Button :show="!source_code" :click="(e) => commands.bullet_list(e)" :toolTip="$t('Editor.bullet')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="isActive.bullet_list() ? 'secondary': 'cell2'" xSmall tile flat iconVal="mdi-format-list-bulleted"/>

                        <Button :show="!source_code" :click="(e) => commands.ordered_list(e)" :toolTip="$t('Editor.order')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="isActive.ordered_list() ? 'secondary': 'cell2'" xSmall tile flat iconVal="mdi-format-list-numbered"/>

                        <Button :show="!source_code" :click="(e) => commands.blockquote(e)" :toolTip="$t('Editor.quote')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="isActive.blockquote() ? 'secondary': 'cell2'" xSmall tile flat iconVal="mdi-format-quote-open"/>

                        <Button :show="!source_code" :click="(e) => commands.code_block(e)" :toolTip="$t('Editor.b_code')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="isActive.code_block() ? 'secondary': 'cell2'" xSmall tile flat iconVal="mdi-xml"/>

                        <Button :show="!source_code" :click="() => dialog_link = true" :toolTip="$t('Editor.link')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="'cell2'" xSmall tile flat iconVal="mdi-link-variant"/>

                        <Button :show="!source_code" :click="() => dialog = true " :toolTip="$t('Editor.l_image')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="'cell2'" xSmall tile flat iconVal="mdi-image"/>

                        <Button :show="!source_code" :click="(e) => commands.horizontal_rule(e)" :toolTip="$t('Editor.separ')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="isActive.horizontal_rule() ? 'secondary': 'cell2'" xSmall tile flat iconVal="mdi-minus"/>

                        <Button :click="(e) => commands.undo(e)" :toolTip="$t('Editor.undo')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="'cell2'" xSmall tile flat iconVal="mdi-undo"/>

                        <Button :click="(e) => full_screen = !full_screen" :toolTip="$t('Editor.full')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="full_screen ? 'secondary': 'cell2'" xSmall tile flat :iconVal=" full_screen ? 'mdi-fullscreen-exit':'mdi-fullscreen' "/>

                        <Button :click="() => editor.clearContent()" :toolTip="$t('Editor.erase')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="'cell2'" xSmall tile flat iconVal="mdi-eraser"/>

                        <Button :click="(e) => source_code = !source_code" :toolTip="$t('Editor.view_code')" styles="max-width: 30px; min-width: 30px" classes="px-0" :color="source_code ? 'secondary' : 'cell2'" xSmall tile flat iconVal="mdi-file-code"/>
                        <v-spacer/>
                        <TokenSelect v-if="tokenSelect" @token="(e) => insertHTML(editor, e)" />
                    </v-row>
                </editor-menu-bar>
                <v-divider v-if="multiligne"/>

                <v-row no-gutters align="end" class="fill-height">
                    <v-col class="fill-height" style="overflow: hidden auto">
                        <editor-content
                                v-show="!source_code"
                                class="editor fill-height"
                                :class="multiligne ? 'multiligne':''"
                                :editor="editor"
                        />
                        <textarea ref="textarea" v-show="source_code" v-model="new_val" class="paragraph black2white--text pa-2" style='width: 100%; resize: vertical;height:auto;'  :style="textareaHeight ? {minHeight: textareaHeight } : {minHeight: '160px'}"/>
                    </v-col>
                </v-row>

                <Dialog v-model="dialog" :title="$t('Editor.add_image')" @close="dialog = false">
                    <template v-slot:content>
                        <div class="pa-3">
                            <TextField v-model="src" :label="$t('Editor.image_link')"/>
                        </div>
                        <v-row class="mx-0">
                            <v-col>
                                <Button
                                        large
                                        block
                                        dark
                                        color="celldark"
                                        :label="$t('global.action.cancel')"
                                        :click="() => { dialog = false; src = null }"
                                />
                            </v-col>
                            <v-col>
                                <Button
                                        :disabled="!src"
                                        large
                                        block
                                        color="primary"
                                        :label="$t('global.action.ok')"
                                        :click="() => addImage()"
                                />
                            </v-col>
                        </v-row>
                    </template>
                </Dialog>
                <Dialog v-model="dialog_link" :title="$t('Editor.add_link')" @close="dialog_link = false">
                    <template v-slot:content>
                        <div class="pa-3">
                            <TextField v-model="link" :label="$t('Editor.link')"/>
                        </div>
                        <v-row class="mx-0">
                            <v-col>
                                <Button
                                        large
                                        block
                                        dark
                                        color="celldark"
                                        :label="$t('global.action.cancel')"
                                        :click="() => { dialog_link = false; link = null }"
                                />
                            </v-col>
                            <v-col>
                                <Button
                                        :disabled="!link"
                                        large
                                        block
                                        color="primary"
                                        :label="$t('global.action.ok')"
                                        :click="() => addLink()"
                                />
                            </v-col>
                        </v-row>
                    </template>
                </Dialog>
            </div>
        </v-hover>

        <v-overlay
                :value="full_screen"
                z-index="9"
                style="align-items: flex-start; justify-content: right"
                opacity="0.9"
        >
            <v-btn icon @click="full_screen = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-overlay>
    </div>
</template>

<script>

    import {Editor, EditorContent, EditorMenuBar} from 'tiptap'
    import {
        Blockquote,
        CodeBlock,
        Heading,
        HorizontalRule,
        OrderedList,
        BulletList,
        ListItem,
        TodoItem,
        TodoList,
        Bold,
        Code,
        Italic,
        Image,
        Link,
        Table,
		TableHeader,
		TableCell,
		TableRow,
        Strike,
        Underline,
        History
    } from 'tiptap-extensions'

    import Alignment from '@/components/editor/Alignment.js'

    import {DOMParser} from 'prosemirror-model'

    export default {
        name: "Editor",
        props: ['value', 'multiligne', 'tokenSelect', 'pevents'],
        components: {
            EditorContent,
            EditorMenuBar,
            Button: () => import('@/components/ui/Button.vue'),
            TextField: () => import('@/components/fields/TextField.vue'),
            TokenSelect: () => import('@/components/fields/TokenSelect.vue'),
            Dialog: () => import('@/components/ui/Dialog.vue')
        },
        data() {
            return {
                source_code: false,
                full_screen: false,
                src: null,
                link: null,
                dialog: false,
                dialog_link: false,
                textareaHeight: '160px',
                editor: new Editor({
                    extensions: [
                        new Blockquote(),
                        new BulletList(),
                        new CodeBlock(),
                        new Heading({levels: [1, 2, 3]}),
                        new HorizontalRule(),
                        new ListItem(),
                        new OrderedList(),
                        new TodoItem(),
                        new TodoList(),
                        new Link(),
                        new Bold(),
                        new Code(),
                        new Italic(),
                        new Image(),
                        new Strike(),
                        new Underline(),
                        new History(),
                        new Table({
							resizable: true,
                        }),
                        new TableHeader(),
						new TableCell(),
                        new TableRow(),
                        new Alignment()
                    ],
                    content: this.value,
                    onUpdate: ({getHTML}) => {
                        let val = getHTML()
                        this.$emit('input', this.$options.filters.striphtml(val) === '' ? null : val)
                    },
                })
            }
        },
        computed: {
            new_val: {
                get() {
                    return this.value
                },
                set(val) {
                    if (this.source_code)
                        this.updateTextarea()

                    this.$emit('input', val)
                }
            },
            focus() {
                return this.editor.focused
            }
        },
        watch: {
            source_code(val) {
                if (val){
                   this.updateTextarea()
                }
                else {
                    this.textareaHeight = '160px'
                }
            }
        },
        methods: {
            toggleAlignment(commands, align, isActive){
                let aligns = ['left', 'center', 'right', 'justify']
                let apply = true

                aligns.forEach(e => {
                    if(isActive.alignment({ align: e})){
                        commands.alignment({ align: e})

                        if(e === align){
                            apply = false
                        }
                    }
                })

                if(apply)
                    commands.alignment({ align: align })
            },
            addImage() {
                this.dialog = false
                this.editor.commands.image({src: this.src})
                this.src = null
            },
            addLink() {
                this.dialog_link = false
                this.editor.commands.link({href: this.link})
                this.link = null
            },
            elementFromString(value) {
                const element = document.createElement('div')
                element.innerHTML = value.trim()
                return element
            },
            insertHTML({state, view}, value) {
                const {selection} = state
                const element = this.elementFromString(value)
                const slice = DOMParser.fromSchema(state.schema).parseSlice(element)
                const transaction = state.tr.insert(selection.anchor, slice.content)
                view.dispatch(transaction)
            },
            updateTextarea(){
                setTimeout(() => {
                    this.textareaHeight = this.$refs['textarea'].scrollHeight.toString() + 'px'
                }, 500)
            },
            setUpHooks(){
                this.pevents.wait('refresh-editor', () => {
                    this.$nextTick(() => {
                        this.editor.setContent(this.new_val)
                    })
                })
            }
        },
        created(){
            this.setUpHooks()
        }
    }
</script>
<style scoped>
    .fullscreen {
        position: fixed !important;
        top: 5%;
        left: 5%;
        z-index: 10;
    }

    .editor.multiligne >>> .ProseMirror {
        min-height: 163px;
        height: 100%;
        padding: 12px
    }

    textarea {
        min-height: 160px;
    }

    .editor >>> .ProseMirror {
        padding: 6px 0 6px 12px;
        min-height: 32px;
        outline: 0;
    }

    .editor >>> p, .editor >>> code {
        font-size: 13px;
    }

    .editor >>> h3 {
        font-size: 16px;
    }

    .editor >>> h2 {
        font-size: 18px;
    }

    .editor >>> h1 {
        font-size: 22px;
    }

    .editor >>> blockquote {
        border-left: 3px solid rgba(0,0,0,.1);
        color: var(--v-label-base);
        padding-left: .8rem;
        font-style: italic;
        margin: 20px auto;
    }
    .editor >>> table {
        border-collapse: collapse;
        margin-bottom: 10px;
    }
    .editor >>> td{
        border: 1px solid var(--v-black2white-base) !important;
        min-width: 30px;
        cursor: col-resize !important;
        padding: 5px;
    }
    .editor >>> td p{
        cursor: text !important;
    }


    .editor >>> .ProseMirror-gapcursor {
        display: none;
        pointer-events: none;
        position: absolute;
    }

    .editor >>> .ProseMirror-gapcursor:after {
        content: "";
        display: block;
        position: absolute;
        top:15px;
        width: 10px;
        border-top: 1px solid var(--v-black2white-base);
        animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
    }

    @keyframes ProseMirror-cursor-blink {
        to {
            visibility: hidden;
        }
    }

    .editor >>> .ProseMirror-focused .ProseMirror-gapcursor {
        display: block;
    }
</style>
